const API_URL = process.env.VUE_APP_API_URL
const API_VERSION = process.env.VUE_APP_API_VERSION

const API_ROUTES = {
	client: {
		get: `${API_URL}/${API_VERSION}/company`,
		find: `${API_URL}/${API_VERSION}/company`,
		save: `${API_URL}/${API_VERSION}/company`,
		delete: `${API_URL}/${API_VERSION}/company`,
		update: `${API_URL}/${API_VERSION}/company/:id/update`,
	},
	brand: {
		get: `${API_URL}/${API_VERSION}/company/:customer_id/brand`,
		find: `${API_URL}/${API_VERSION}/company/:customer_id/brand/:id`,
		save: `${API_URL}/${API_VERSION}/company/:customer_id/brand/:id`,
		delete: `${API_URL}/${API_VERSION}/company/:customer_id/brand/:id`,
		update: `${API_URL}/${API_VERSION}/company/:customer_id/brand/:id`,
	},
	country: {
		get: `${API_URL}/${API_VERSION}/countries`,
	},
	departament: {
		get: `${API_URL}/${API_VERSION}/departments`,
	},
	city: {
		get: `${API_URL}/${API_VERSION}/cities`,
	},
	user: {
		get: `${API_URL}/${API_VERSION}/users`,
		find: `${API_URL}/${API_VERSION}/users`,
		save: `${API_URL}/${API_VERSION}/users`,
		delete: `${API_URL}/${API_VERSION}/users`,
		update: `${API_URL}/${API_VERSION}/users`,
	},
	images: {
		image: `${API_URL}/`,
	},
}

export { API_ROUTES }
